<template>
    <v-container>
<v-row>
    <v-col>
<v-card min-height="70vh">
               <v-card-text>
                   <v-row>
                       <v-col>
                            <v-text-field
                    v-model="name"
                        name="name"
                        label="账号"
                        
                    ></v-text-field>
                    <v-text-field
                    v-model="password"
                        name="pwd"
                        label="密码"
                        
                    ></v-text-field>
                    <v-btn color="primary" @click="login">登录</v-btn>
                        </v-col>
                        <v-divider vertical class="d-none d-sm-block"></v-divider>
                        <v-col>
                            detail
                            ...
                        </v-col>
                   </v-row>
               </v-card-text>
                </v-card>
    </v-col>
</v-row>
                

    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                name: "",
                password:""
            }
        },
        methods: {
            login() {
                this.axios.post("user/login",{
                    name: this.name,
                    password: this.password,
                }).then(e=>console.log(e))
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>