<template>
  <v-app>
    <appBar></appBar>
    <v-main class="mainbg">
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
     <!-- footer -->
      <v-footer app class="mainbg" absolute>
        <v-container>
          <v-row>
            <v-col>
              <v-tooltip color="success" top v-model="showStarTip">
                <template v-slot:activator="{}">
                  <v-btn @click="onStar" rounded color="primary">
                    <v-icon left>mdi-heart-circle</v-icon>
                    赞 {{ star }}
                  </v-btn>
                </template>
                <span>👌 感谢鼓励~</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
  </v-app>
</template>

<script>
import appBar from "./components/appBar";
export default {
  components: {
    appBar,
  },
  data() {
    return {
      star: 0,
      showStarTip: false,
    };
  },
  methods: {
    link(e) {
      window.open(e);
    },
    onStar() {
      this.axios.get("index/setStar").then(() => {
        // console.log(response);
      });
      this.star += 1;
      if (this.showStarTip == true) return true;
      this.showStarTip = true;
      setTimeout(() => {
        this.showStarTip = false;
      }, 2000);
    },
  },
  mounted() {},
  created() {
    this.axios.get("index/getStar").then((response) => {
      // console.log(response);
      this.star = response.data.star;
    });
  },
};
</script>