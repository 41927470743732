<template>
  <v-app-bar app>
    <v-container class="pa-0 fill-height">
      <v-app-bar-nav-icon
        ><router-link to="/" class="text-decoration-none text--primary"
          ><v-icon>mdi-math-integral-box</v-icon></router-link
        ></v-app-bar-nav-icon
      >
      <v-toolbar-title class="ml-2 mr-10">
        <router-link to="/" class="text-decoration-none text--primary">
          步骤计算器
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <avatar></avatar>
      <showMode></showMode>
    </v-container>
  </v-app-bar>
</template>

<script>
import showMode from "./showMode";
import avatar from "./avatar";
export default {
  components: {
    showMode,
    avatar,
  },
  data() {
    return {};
  },
  created() {},
};
</script>