<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" class="ma-auto">
        <v-card>
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="blue accent-4"
              color="white"
              center-active
              dark
            >
              <v-tab
                v-for="(item, index) in subjectItems"
                :href="item.href"
                :key="index"
              >
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </v-card>
          <v-container>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(subjectItem, index) in subjectItems"
                :value="subjectItem.name"
                :key="index"
              >
                <v-row v-for="(list, index) in subjectItem.list" :key="index">
                  <v-col cols="12">
                    <div class="text-h5">{{ list.title }}</div>
                  <div v-for="(object, index) in list.object" :key="index">
                    <v-col class="text-h6 pb-0" cols="12" v-if="object.subtitle"># {{ object.subtitle }}</v-col>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="mx-2 mb-4"
                            v-for="(item, index) in object.items"
                            :key="index"
                            :to="item.to"
                          >
                            {{ item.name }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    tab: null,
    subjectItems: [
      {
        name: "初中",
        href: "#初中",
        list: [
          {
            title: "something",
            object: [
              {
                subtitle: "more",
                items: [
                  {
                    name: "dd",
                    to: "/",
                  },
                  {
                    name: "dsd",
                    to: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "高中",
        href: "#高中",
        list:[
          {
            title:'线性代数',
            object:[
              {
                subtitle:'矩阵',

              }
            ]
          }
        ]
      },
      {
        name: "大学",
        href: "#大学",
        list:[
          {
            title:'高等数学',
            object:[
              {
                subtitle:'导数与微分',
                items:[
                  {
                    name:'进入计算',
                    to:'/der'
                  }
                ]
              },
            ]
          },
          {
            title:'线性代数',
            object:[
              {
                subtitle:'矩阵与行列式',
                items:[
                  {
                    name:'进入计算',
                    to:'/det'
                  }
                ]
              },
            ]
          }
        ]
      },
    ],
  }),
  methods: {
    
  },
  created() {
    this.axios.get("index/getStar").then((response) => {
      this.star = response.data.star;
    });
  },
};
</script>