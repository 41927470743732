<template>
    <v-menu offset-y open-on-hover close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" class="ml-4" size="32">
            <!-- <v-icon>mdi-account-circle</v-icon> -->
            <v-icon>mdi-account-key</v-icon>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item
          v-if="!hasLogin"
            to="/login"
          >
            <v-list-item-content>
              <v-list-item-title class="title">
                你好，请登录！
              </v-list-item-title>
              <v-list-item-content>登录后可使用更多功能！ </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
          v-else
          >
            <v-list-item-content>
              <v-list-item-title class="title">
                你好，{{name}}！
              </v-list-item-title>
              <v-list-item-content>欢迎登录！ </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="
              '';

            "
          >
            <v-list-item-icon>
              <v-icon>mdi-account-settings</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>资料设置</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>退出</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
</template>

<script>
    export default {
        data() {
          return {
            name: "test1",
            hasLogin:false
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>